<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="header flex">
      <div class="logo_box flex">
        <img src="@/assets/user/logo.png" alt="" @click="$router.push('/')"/>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/placard_user/placard_index' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item><a>{{ $route.matched[1].meta }}</a></el-breadcrumb-item>
          <el-breadcrumb-item v-if="$route.matched[2] && !$route.matched[2].meta"><a>{{
              $route.matched[2].meta
            }}</a></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <user_top_info/>
    </div>
    <div class="flex box">
      <el-menu class="el-menu-vertical-demo el_menu_box" :default-active="menu_active" @open="handleOpen"
               @select="handleSelect" background-color="#108CDD" text-color="#fff" active-text-color="#ffd04b"
               :unique-opened="true" :router="true">
        <!-- :router="true" -->
        <template v-for="item in placardList ">
          <el-submenu v-if="item.children && item.children.length > 0"
                      :index="item.path ? item.path : item.id.toString()">
            <template slot="title">
              <div class="menu_box">
                <img :src="item.imgUrl" alt="icon" style="width: 20px; margin-right: 10px;"/>
                {{ item.title }}
              </div>
            </template>
            <el-menu-item v-for="child in  item.children " :key="child.id" :index="child.path">
              <div class="menu_box">
                {{ child.title }}
                <!-- {{ child.order_status_total_num }} -->
                <!-- v-if="item.hasOwnProperty('order_status_total_num') && child.order_status_total_num != 0" -->
                <el-badge :value="child.order_status_total_num" class="item_badge"
                          v-if="child.hasOwnProperty('order_status_total_num') && child.order_status_total_num != 0"
                          :max="99">
                </el-badge>
                <el-badge :value="child.draft_status_total_num" class="item_badge"
                          v-if="child.hasOwnProperty('draft_status_total_num') && child.draft_status_total_num != 0"
                          :max="99">
                </el-badge>
              </div>

            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :index="item.path" @click="goCenter(item)">
            <div class="menu_box">
              <img :src="item.imgUrl" alt="icon" style="width: 20px; margin-right: 10px;"/>
              {{ item.title }}
            </div>

          </el-menu-item>
        </template>

      </el-menu>
      <div class="router_view">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import user_top_info from '@/components/user_top_info'
import {curlGet} from "@/api/request";

export default {
  created() {
    //获取用户信息，存到本地
    this.getUserInfo()
    //非广告主-菜单
    this.nonAdvertiserDeletionMenu()
  },

  components: {
    user_top_info
  },
  computed: {
    ...mapState(['menu_active', 'placard_list', 'placard',]),
    ...mapMutations('placard', ['SET_DATA'], ['PLACARD_LIST_DEL']),
    // 监听vuex中的mediaOrder中的数据是否发生改变,如果发生改变则需要重新替换左侧导航栏中的提示条数
    mediaOrderStatus() {
      return this.mediaOrder
    },
    // packOrderStatus() {
    // 	return this.packOrder
    // },
    // manuscriptStatus() {
    // 	return this.manuscript
    // }
  },
  watch: {
    'placard.order_form.category_id': {
      handler(newVal) {
        localStorage.setItem('vuex', JSON.stringify(this.$store.state))
        this.getOrderNum(this.placard.order_form.category_id)
      },
      deep: true,
    },
    'placard.placard_manuscript_category_id': {
      handler(newVal) {
        localStorage.setItem('vuex', JSON.stringify(this.$store.state))
        this.getOrderNum(this.placard.placard_manuscript_category_id)
      },
      deep: true,
    }
  },
  mounted() {
    this.fullscreenLoading = true
    // 轮询查询次数
    this.timer = setInterval(this.getOrderNum(), 6000)
    this.fullscreenLoading = false
    if (localStorage.getItem('user_info')) {
      this.user_info = JSON.parse(localStorage.getItem('user_info'))
    }
  },
  data() {
    return {
      fullscreenLoading: false,
      user_info: this.$util.getLocalStorage('user_info'),
      timer: undefined,
      placardList: []
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.$store.commit('SETACTIVEMENU', keyPath[1] ? keyPath[1] : keyPath[0])
    },
    handleSelect(index, indexPath) {
      console.log('index', index, indexPath);
      if (indexPath[1] == '/placard_user/pub_placard/pub_outdoors') {
        this.$store.commit('SET_DATA', {
          form: 'pub_index',
          value: 0
        })
      }
      this.$store.commit('SETACTIVEMENU', indexPath[1])
    },
    goCenter(item) {
      // console.log('点击首页',item)
      // console.log(item.path)
      this.$store.commit('SETACTIVEMENU', item.path)
    },
    // 退出
    quit() {
      this.$confirm('确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/user/logout').then(res => {
          if (res.data.code) {
            this.$message({
              offset: 60,
              type: 'success',
              message: res.data.msg
            });
            this.$util.setLocalStorage('user_info', res.data.data, 200) // 写入local
            localStorage.clear()	// 清除local
            sessionStorage.clear()
            this.$router.push('/')					// 跳转首页
          }
        })

      }).catch(() => {
        // this.$message({
        // 	type: 'info',
        // 	message: '已取消退出登录'
        // });
      });
    },
    getOrderNum(id) {
      let data = {}
      if (id) {
        data.category_id = id
      }
      this.curlGet('/api/users/guanggao_order/num', data).then(res => {
        if (res.data.code) {
          // this.draft_status_num = res.data.data
          // ['draft_status_total_num']
          this.placard_list.forEach((item, index) => {
            if (item.children) {
              this.placard_list[index].children.forEach((el, i) => {
                if (el.order_type == 'order') {
                  el.order_status_total_num = res.data.data.order_status_total_num
                }
                if (el.order_type == 'draft') {
                  el.draft_status_total_num = res.data.data.draft_status_total_num
                }
              })
            }

            let user_info = JSON.parse(localStorage.getItem('user_info'));
            if (user_info.is_guanggao === 0) {
              this.placardList = this.placard_list.filter(item => {
                return item.title !== '我的媒介'
              })
            } else {
              this.placardList = this.placard_list
            }

          });

          // console.log('draft_status_num:', this.draft_status_num);
        }
      })
    },
    nonAdvertiserDeletionMenu() {
      this.$store.dispatch('updateUserInfo').then(() => {
        this.$store.dispatch('is_Advertiser');
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/userNav';
</style>